<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header">
                <div class="modal_info">
                    <h1>Client Portal <span>Cover Setting</span></h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()">
                    <i class="fas fa-long-arrow-alt-right"></i>
                </button>
            </div>
            <Form @submit="handleSubmit" v-slot="{ errors }" class="client-portal-cover-setting-form">
                <div class="modal_body pt-4">
                    <label for="dashboard-cover" class="switch_option capsule_btn border-0 pt-0">
                        <h5 class="large">Display Dashboard Cover</h5>
                        <input type="checkbox" id="dashboard-cover" :true-value="1" :false-value="0" v-model="form.display_cover" hidden>
                        <div><span></span></div>
                    </label>
                    <div class="cover_type">
                        <h3 class="sub_header">Cover Type</h3>
                        <ul class="type_list">
                            <li>
                                <label for="clr_text">
                                    <input type="radio" id="clr_text" :value="1" v-model="form.cover_type" hidden>
                                    <img src="@/assets/images/background.svg" alt="">
                                    <h5>Color + Text</h5>
                                </label>
                            </li>
                            <li>
                                <label for="img_text">
                                    <input type="radio" id="img_text" :value="2" v-model="form.cover_type" hidden>
                                    <img src="@/assets/images/picture.svg" alt="">
                                    <h5>Image + Text</h5>
                                </label>
                            </li>
                            <li>
                                <label for="img_only">
                                    <input type="radio" id="img_only" :value="3" v-model="form.cover_type" hidden>
                                    <img src="@/assets/images/picture.svg" alt="">
                                    <h5>Image Only</h5>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div v-if="form.cover_type !== 3">
                        <div class="setting_wpr mt-3">
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Headline <button type="button" class="px-1" @click="headlineSetting = !headlineSetting"><i class="fas fa-ellipsis-h"></i></button></label>
                                    <div class="field_wpr" :class="{ 'has-error': errors.headline }">
                                        <Field autocomplete="nofill" type="text" name="headline" v-model="form.headline" placeholder="Compelling Headline Goes Here" />
                                    </div>
                                    <ErrorMessage name="headline" class="text-danger" />
                                </div>
                            </div>
                            <div v-if="headlineSetting">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Font-Family</label>
                                        <div class="field_wpr m-0">
                                            <multiselect v-model="form.headline_setting.font_family" v-bind="fontFamily"></multiselect>
                                        </div>
                                    </div>
                                    <div class="group_item">
                                        <label class="input_label">Font-Weight</label>
                                        <div class="field_wpr m-0">
                                            <multiselect v-model="form.headline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp mb-2">
                                    <div class="group_item">
                                        <label class="input_label">Size</label>
                                        <div class="field_wpr m-0">
                                            <Field autocomplete="nofill" type="number" name="headline_setting_font_size" v-model="form.headline_setting.font_size" min="0" max="100" placeholder="90" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp mb-2">
                                    <div class="group_item">
                                        <label class="input_label">Color</label>
                                        <Field autocomplete="off" name="headline_setting_font_color" v-model="form.headline_setting.font_color" type="text" label="element color">
                                            <color-picker v-model="form.headline_setting.font_color" :classes="{ 'has-error': errors.headline_setting_font_color }" />
                                        </Field>
                                        <ErrorMessage name="headline_setting_font_color" class="text-danger" />
                                    </div>
                                </div>
                                <div class="cover_type mt-4 mb-3 pb-4">
                                    <label for="headline_shadow" class="switch_option capsule_btn">
                                        <h5 class="large">Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                        <input type="checkbox" id="headline_shadow" :true-value="1" :false-value="0" v-model="form.headline_setting.has_shadow" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div v-if="form.headline_setting.has_shadow">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Offset-x</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="nofill" type="number" name="headline_setting_shadow_x" v-model="form.headline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Offset-y</label>
                                                <div class="field_wpr ">
                                                    <Field autocomplete="nofill" type="number" name="headline_setting_shadow_y" v-model="form.headline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Blur-Radius</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="nofill" type="number" name="headline_setting_shadow_blur" v-model="form.headline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Shadow Color</label>
                                                <Field autocomplete="off" name="headline_setting_shadow_color" v-model="form.headline_setting.shadow_color" type="text" label="element color">
                                                    <color-picker v-model="form.headline_setting.shadow_color" :classes="{ 'has-error': errors.headline_setting_shadow_color }" />
                                                </Field>
                                                <ErrorMessage name="headline_setting_shadow_color" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form_grp">
                                <div class="group_item">
                                    <label class="input_label">Sub Headline <button type="button" class="px-1" @click="subHeadlineSetting = !subHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button></label>
                                    <div class="field_wpr">
                                        <Field autocomplete="nofill" type="text" name="sub_headline" v-model="form.description" placeholder="Enter text here" />
                                    </div>
                                </div>
                            </div>
                            <div v-if="subHeadlineSetting">
                                <div class="form_grp">
                                    <div class="group_item">
                                        <label class="input_label">Font-Family</label>
                                        <div class="field_wpr m-0">
                                            <multiselect v-model="form.subheadline_setting.font_family" v-bind="fontFamily"></multiselect>
                                        </div>
                                    </div>
                                    <div class="group_item">
                                        <label class="input_label">Font-Weight</label>
                                        <div class="field_wpr m-0">
                                            <multiselect v-model="form.subheadline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp mb-2">
                                    <div class="group_item">
                                        <label class="input_label">Size</label>
                                        <div class="field_wpr m-0">
                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_font_size" v-model="form.subheadline_setting.font_size" min="0" max="100" placeholder="90" />
                                        </div>
                                    </div>
                                </div>
                                <div class="form_grp mb-2">
                                    <div class="group_item">
                                        <label class="input_label">Color</label>
                                        <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.subheadline_setting.font_color" type="text" label="element color">
                                            <color-picker v-model="form.subheadline_setting.font_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                        </Field>
                                        <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                    </div>
                                </div>
                                <div class="cover_type mt-4 mb-3 pb-4">
                                    <label for="subheadline_shadow" class="switch_option capsule_btn">
                                        <h5 class="large">Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                        <input type="checkbox" id="subheadline_shadow" :true-value="1" :false-value="0" v-model="form.subheadline_setting.has_shadow" hidden>
                                        <div><span></span></div>
                                    </label>
                                    <div v-if="form.subheadline_setting.has_shadow">
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Offset-x</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_x" v-model="form.subheadline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                </div>
                                            </div>
                                            <div class="group_item">
                                                <label class="input_label">Offset-y</label>
                                                <div class="field_wpr ">
                                                    <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_y" v-model="form.subheadline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-3">
                                            <div class="group_item">
                                                <label class="input_label">Blur-Radius</label>
                                                <div class="field_wpr">
                                                    <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_blur" v-model="form.subheadline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp mb-2">
                                            <div class="group_item">
                                                <label class="input_label">Shadow Color</label>
                                                <Field autocomplete="off" name="subheadline_setting_shadow_color" v-model="form.subheadline_setting.shadow_color" type="text" label="element color">
                                                    <color-picker v-model="form.subheadline_setting.shadow_color" :classes="{ 'has-error': errors.subheadline_setting_shadow_color }" />
                                                </Field>
                                                <ErrorMessage name="subheadline_setting_shadow_color" class="text-danger" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="form.cover_type !== 1">
                        <h3 class="sub_header">Cover Image</h3>
                        <image-library v-model="form.cover" image-type="public-cover" upload-text="Cover" />
                        <div class="cover_type mt-5 mb-2 py-2">
                            <label for="small_image" class="switch_option capsule_btn">
                                <h5 class="large">Small Screen Image</h5>
                                <input type="checkbox" id="small_image" :true-value="1" :false-value="0" v-model="form.has_small_screen_cover" hidden>
                                <div><span></span></div>
                            </label>
                            <image-library v-if="form.has_small_screen_cover" v-model="form.small_cover" image-type="public-cover-small" upload-text="Cover" />
                        </div>
                        <label for="overlay" class="switch_option capsule_btn">
                            <h5 class="large">Add Image Overlay</h5>
                            <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="form.has_cover_overlay" hidden>
                            <div><span></span></div>
                        </label>
                        <div v-if="form.has_cover_overlay">
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Overlay Color</label>
                                    <Field autocomplete="off" name="overlay_color" v-model="form.overlay_color" type="text" label="element color">
                                        <color-picker v-model="form.overlay_color" :classes="{ 'has-error': errors.overlay_color }" />
                                    </Field>
                                    <ErrorMessage name="overlay_color" class="text-danger" />
                                </div>
                            </div>
                            <div class="form_grp mb-2">
                                <div class="group_item">
                                    <label class="input_label">Overlay Opacity</label>
                                    <div class="field_wpr">
                                        <Field autocomplete="nofill" name="overlay_opacity" v-model="form.overlay_opacity" type="number" min="0" max="100" placeholder="90" label="element color" />
                                    </div>
                                    <ErrorMessage name="overlay_opacity" class="text-danger" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="form.cover_type === 1" class="mt-2">
                        <div class="form_grp mb-2">
                            <div class="group_item">
                                <label class="input_label">Background Color</label>
                                <Field autocomplete="off" name="cover_background_color" v-model="form.cover_background_color" type="text" label="element color">
                                    <color-picker v-model="form.cover_background_color" :classes="{ 'has-error': errors.cover_background_color }" />
                                </Field>
                                <ErrorMessage name="cover_background_color" class="text-danger" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal_footer">
                    <button :disabled="updateLoader" class="btn cancel_btn" type="button" @click="closeModal()">Cancel</button>
                    <button :disabled="updateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="updateLoader"></i> {{ updateLoader ? 'Saving' : 'Save' }}</button>
                </div>
            </Form>
        </div>
    </div>
</template>

<script>
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions } from 'vuex'

    export default {
        name: 'Client Portal Cover Setting',

        data () {
            return {
                subHeadlineSetting: false,
                headlineSetting: false,
                form: {
                    display_cover: 0,
                    cover_type: 2,
                    headline_setting: {},
                    subheadline_setting: {},
                    cover: '',
                    has_small_screen_cover: 0,
                    small_cover: '',
                    has_cover_overlay: 0,
                    overlay_color: '',
                    cover_background_color: '',
                    overlay_opacity: 20,
                },
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Field,
            Form,
            ErrorMessage,
        },

        watch: {
            modelValue () {
                const vm = this;

                vm.subHeadlineSetting = false;
                vm.headlineSetting    = false;
                vm.resetForm();
            },
        },

        computed: mapState({
            clientPortal: state => state.clientPortalModule.clientPortal,
            updateLoader: state => state.clientPortalModule.updateLoader,
            fontFamily: state => state.commonModule.fontFamily,
            fontWeight: state => state.commonModule.fontWeight,
        }),

        mounted () {
            const vm = this;

            vm.resetForm();
        },

        methods: {
            ...mapActions({
                updatePortalSetting: 'clientPortalModule/updatePortalSetting',
            }),

            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;
                let headline_setting = vm.clientPortal.headline_setting ? JSON.parse(vm.clientPortal.headline_setting) : {};
                let subheadline_setting = vm.clientPortal.subheadline_setting ? JSON.parse(vm.clientPortal.subheadline_setting) : {};

                if (!headline_setting.font_size) {
                    headline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    };
                }
                if (!subheadline_setting.font_size) {
                    subheadline_setting = {
                        font_family: 'Inter',
                        font_weight: 600,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.68)',
                    };
                }


                vm.form = {
                    headline_setting,
                    subheadline_setting,
                    display_cover: vm.clientPortal.display_cover ? vm.clientPortal.display_cover : 0,
                    headline: vm.clientPortal.headline ? vm.clientPortal.headline : '',
                    description: vm.clientPortal.description ? vm.clientPortal.description : '',
                    cover_type: vm.clientPortal.cover_type ? vm.clientPortal.cover_type : 2,
                    cover: vm.clientPortal.cover ? vm.clientPortal.cover : '',
                    has_small_screen_cover: vm.clientPortal.has_small_screen_cover ? vm.clientPortal.has_small_screen_cover : 0,
                    small_cover: vm.clientPortal.small_cover ? vm.clientPortal.small_cover : '',
                    has_cover_overlay: vm.clientPortal.has_cover_overlay ? vm.clientPortal.has_cover_overlay : 0,
                    overlay_color: vm.clientPortal.overlay_color ? vm.clientPortal.overlay_color : '',
                    overlay_opacity: vm.clientPortal.overlay_opacity ? vm.clientPortal.overlay_opacity : 20,
                    cover_background_color: vm.clientPortal.cover_background_color ? vm.clientPortal.cover_background_color : '#2F7FED',
                };
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.form));

                params.setFieldError = setFieldError;
                params.headline_setting = JSON.stringify(params.headline_setting);
                params.subheadline_setting = JSON.stringify(params.subheadline_setting);

                vm.updatePortalSetting(params).then((result) => {
                    if (result) {
                        vm.closeModal();
                    }
                });
            },
        },
    }
</script>

<style scoped>
    :deep(.client-portal-cover-setting-form) {
        height: calc(100vh - 180px);
    }

    :deep(.client-portal-cover-setting-form .group_item) {
        width: 100%;
    }

    :deep(.form_grp .group_item label.input_label) {
        font-size: 15px;
        line-height: 20px;
        color: #121525;
        font-weight: 400;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        margin-top: auto;
    }
</style>
